import { ReactComponent as AnswerSign1 } from '../assets/icons/answerSign1.svg';
import { ReactComponent as AnswerSign2 } from '../assets/icons/answerSign2.svg';
import { ReactComponent as AnswerSign3 } from '../assets/icons/answerSign3.svg';
import { ReactComponent as AnswerSign4 } from '../assets/icons/answerSign4.svg';
import TaskImage57 from '../assets/images/taskImage57.png';
import TaskImage58 from '../assets/images/taskImage58.png';
import TaskImage59 from '../assets/images/taskImage59.png';
import TaskImage79 from '../assets/images/taskImage79.png';
import TaskAudio37 from '../assets/audio/taskAudio37.mp4';
import TaskAudio38 from '../assets/audio/taskAudio38.mp4';
import TaskAudio39 from '../assets/audio/taskAudio39.mp4';
import TaskAudio40 from '../assets/audio/taskAudio40.mp4';
import TaskAudio41 from '../assets/audio/taskAudio41.mp4';
import TaskAudio42 from '../assets/audio/taskAudio42.mp4';

export const CHANCE_CARDS = [
  {
    id: 1,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, которая ходит после вас. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 2,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, которая ходит после вас. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 3,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, которая ходит после вас. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 4,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, ходившей перед вами. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 5,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, ходившей перед вами. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 6,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, ходившей перед вами. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 7,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 8,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 9,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 10,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 11,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 12,
    title: 'Продвижение',
    description: 'ВТБ поощряет стремление и усердие! Идите вперед на 4 клетки, если у вас есть 3+ любых навыка',
  },
  {
    id: 13,
    title: 'Продвижение',
    description: 'ВТБ поощряет стремление и усердие! Идите вперед на 4 клетки, если у вас есть 3+ любых навыка',
  },
  {
    id: 14,
    title: 'Продвижение',
    description: 'ВТБ поощряет стремление и усердие! Идите вперед на 4 клетки, если у вас есть 3+ любых навыка',
  },
  {
    id: 15,
    title: 'Продвижение',
    description: 'ВТБ поощряет стремление и усердие! Идите вперед на 4 клетки, если у вас есть 3+ любых навыка',
  },
  {
    id: 16,
    title: 'Продвижение',
    description: 'ВТБ поощряет стремление и усердие! Идите вперед на 4 клетки, если у вас есть 3+ любых навыка',
  },
  {
    id: 17,
    title: 'Удача не на твоей стороне',
    description:
      'Вы планировали посетить онлайн-лекцию по теме тайм-менеджмента, но в самом начале трансляции интернет отключился из-за неполадок на линии. \n\nПропустите ход.',
  },
  {
    id: 18,
    title: 'Удача не на твоей стороне',
    description:
      'Вы планировали посетить онлайн-лекцию по теме тайм-менеджмента, но в самом начале трансляции интернет отключился из-за неполадок на линии. \n\nПропустите ход.',
  },
  {
    id: 19,
    title: 'Удача не на твоей стороне',
    description:
      'Вы планировали посетить онлайн-лекцию по теме тайм-менеджмента, но в самом начале трансляции интернет отключился из-за неполадок на линии. \n\nПропустите ход.',
  },
  {
    id: 20,
    title: 'Передача хода',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы передаете задание команде, ходившей перед вами. Если эта команда выполняет задание, вы вместе двигаетесь вперед по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
  {
    id: 21,
    title: 'Аукцион',
    description:
      'Эту карточку можно использовать, когда попадается сложное задание. В этом случае вы выставляете задание на аукцион. Команда, которая первой верно ответит на задание, двигается вперед вместе с вами по игровому полю на то количество клеток, которое указано в задании. Если задание не выполнено, остаетесь на месте.',
  },
];

export const SKILL_CARDS = [
  {
    id: 1,
    title: 'Навык: Командная работа',
    description:
      'А ты знал, что у сотрудников ВТБ есть своя валюта?\nЕсли нет, то теперь ты стал немного ближе к команде ВТБ\nВТБшки — внутренняя валюта, которой награждаются сотрудники за выполнение показателей (можно приобретать брендированную продукцию).',
  },
  {
    id: 2,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Сообщение от наставника \n\nПривет, Стажёр! \nОтправляю ссылку на приложение, где ты найдешь свое расписание. \nПиши, если не разберешься с интерфейсом.',
  },
  {
    id: 3,
    title: 'Навык: Знаток Истории',
    description:
      'Исторический ликбез: \nВ 2020 году банк ВТБ занял первое место в рейтинге Forbes по числу клиентов-долларовых миллионеров.',
  },
  {
    id: 4,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Сообщение от наставника \n\nПривет, стажёр!\nОтправляю тебе расписание твоих задач на неделю! Ознакомься внимательно, до встречи в понедельник!',
  },
  {
    id: 5,
    title: 'Навык: Знаток банка',
    description:
      'Сообщение от наставника \n\nПривет, Стажёр! \nНапоминаю номера экстренной помощи\n \n112 — МЧС\n8 (800) 100-24-24 — ВТБ',
  },
  {
    id: 6,
    title: 'Навык: Кот Леопольд',
    description:
      'Первое правило оператора (работника):\n\nСпокойствие, только спокойствие!\nВыдержка важна в любой ситуации, даже если клиент так не думает.',
  },
  {
    id: 7,
    title: 'Навык: Знаток Истории',
    description:
      'А ты знал, что первый зарубежный банк был открыт в Анголе? Да-да, в той самой Африканской стране, находящейся аж на другом континенте! Произошло это 2 сентября 2006 года, а на церемонии присутствовал сам министр иностранных дел.',
  },
  {
    id: 8,
    title: 'Навык: Лучше, чем ПоверПоинт',
    description:
      'Умение правильно презентовать продукт и компанию крайне важно! Это не очень сложно, достаточно придерживаться пары несложных правил:\nВажно показать преимущества компании и продукта, которые основаны на пожеланиях клиента (выявленных потребностях).',
  },
  {
    id: 9,
    title: 'Навык: Юридическая подкова',
    description:
      'Сообщение от наставника\n\nПривет, Стажёр! \n\n Рады видеть тебя в наших рядах, не забудь завтра принести Паспорт, он понадобится для оформления зарплатной карты.',
  },
  {
    id: 10,
    title: 'Навык: Знаток банка',
    description:
      'Помни, если клиент обратился к тебе за помощью по вопросам связанным с картой, то ты можешь запросить кодовое слово, но не пароли от карты или приложения, эта информация тебе ни к чему.',
  },
  {
    id: 11,
    title: 'Навык: Благотворительность',
    description:
      'ВТБ – это не только технологии, но и любовь к природе. Мы стараемся обеспечивать комфортную жизнь не только своим клиентам, но также заботимся о животных. Наши фонды помогают амурским тиграм и зубрам.',
  },
  {
    id: 12,
    title: 'Навык: Юридическая подкова',
    description:
      'Привет, стажёр! Жду сканы твоих документов, чтобы подписать договор. \nНапоминаю, что договор ты подписываешь с ПАО "ВТБ"',
  },
];

export const TASK_CARDS = [
  {
    id: 1,
    number: 1,
    type: 'answers',
    answers: [
      { text: 'Мир без слез', sign: AnswerSign1, correct: true },
      { text: '#ЩедрыйВторник', sign: AnswerSign2, correct: false },
      { text: 'Фонд помощи взрослым «Живой»', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Благотворительность',
    description: 'Какой из этих благотворительных проектов реализует ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 2,
    number: 2,
    type: 'answers',
    answers: [
      { text: 'Паспорт, СНИЛС и ИНН', sign: AnswerSign1, correct: false },
      { text: 'Усы, лапы и хвост', sign: AnswerSign2, correct: false },
      { text: 'Паспорт, СНИЛС, свидетельство о рождении', sign: AnswerSign3, correct: false },
      { text: 'Паспорт', sign: AnswerSign4, correct: true },
    ],
    title: 'Навык: Юридическая подкова',
    description:
      'Вы работаете сотрудником, какие документы необходимо принести клиенту, чтобы вы смогли оформить банковскую карту?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 3,
    number: 3,
    type: 'answers',
    answers: [
      { text: 'ВТБ активно занимается благотворительностью', sign: AnswerSign1, correct: false },
      { text: 'В ВТБ нет карьерного роста', sign: AnswerSign2, correct: true },
      { text: 'ВТБ проводит спортивные мероприятия для своих сотрудников', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Тайм-менеджмент',
    description: 'Что из этих утверждений НЕВЕРНО?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 4,
    number: 4,
    type: 'answers',
    answers: [
      { text: 'Согласиться с клиентом и сделать все, как он хочет', sign: AnswerSign1, correct: false },
      { text: 'Выслушать клиента и предложить оптимальное решение', sign: AnswerSign2, correct: true },
      { text: 'Проигнорировать', sign: AnswerSign3, correct: false },
      { text: 'Уехать в отпуск', sign: AnswerSign4, correct: false },
    ],
    title: 'Навык: Кот Леопольд',
    description: 'Что делать, если клиент нагрубил?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 5,
    number: 5,
    type: 'answers',
    answers: [
      { text: 'Пин код', sign: AnswerSign1, correct: false },
      { text: 'Кодовое слово', sign: AnswerSign2, correct: true },
      { text: 'CVV', sign: AnswerSign3, correct: false },
      { text: 'Пароль от приложения ВТБ', sign: AnswerSign4, correct: false },
    ],
    title: 'Навык: Знаток банка',
    description:
      'В случае, если вы потеряли карту и хотите её восстановить, обратившись в Call-центр, что работник банка может попросить вас назвать?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 6,
    number: 6,
    type: 'answers',
    answers: [
      { text: 'Так называют новых сотрудников ВТБ', sign: AnswerSign1, correct: false },
      { text: 'Внутренняя валюта для сотрудников', sign: AnswerSign2, correct: true },
      { text: 'Внутренние очки рейтинга для сотрудников', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Корпоративная культура',
    description: 'Что такое ВТБшки?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 7,
    number: 7,
    type: 'answers',
    answers: [
      {
        text: 'В 2020 году банк занял первое место в России в рейтинге Forbes по числу клиентов-долларовых миллионеров',
        sign: AnswerSign1,
        correct: true,
      },
      { text: 'Впервые банк был зарегистрирован в Казани', sign: AnswerSign2, correct: false },
      { text: 'Изначально банк назывался Банк Внутренней Торговли', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Знаток истории',
    description: 'Что из этих фактов является правдой?',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
  },
  {
    id: 8,
    number: 8,
    type: 'answers',
    answers: [
      { text: 'В Анголе', sign: AnswerSign1, correct: true },
      { text: 'В Малайзии', sign: AnswerSign2, correct: false },
      { text: 'В Катаре', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Знаток банка',
    description: 'В какой из зарубежных стран был открыт филиал банка ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 9,
    number: 9,
    type: 'answers',
    answers: [
      { text: 'Песцов, белых медведей', sign: AnswerSign1, correct: false },
      { text: 'Амурских тигров, зубров', sign: AnswerSign2, correct: true },
      { text: 'Кавказскую лесную кошку, Каспийских тюленей', sign: AnswerSign3, correct: false },
    ],
    title: 'Навык: Благотворительность',
    description: 'Каких диких животных опекает ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 10,
    number: 10,
    description: 'Назовите 3 предмета, без которых нельзя обойтись в офисе.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 11,
    number: 11,
    title: 'Навык: Работник месяца',
    description: 'Назовите 3 качества, важных для менеджера.',
    subDescription: 'За каждый верный ответ вы продвигаетесь вперед на 2 шага.',
    timer: 20,
  },
  {
    id: 12,
    number: 12,
    title: 'Навык: Юридическая подкова',
    description: 'Назовите полное юридическое название ВТБ.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
    answer: 'Публичное акционерное общество (ПАО) ВТБ',
  },
  {
    id: 13,
    number: 13,
    title: 'Навык: Знаток банка',
    description: 'Назовите три главные буквы ВТБ в алфавитном порядке.',
    subDescription: 'За правильный ответ двигайтесь на 4 шага вперед.',
    timer: 15,
    answer: 'БВТ',
  },
  {
    id: 14,
    number: 14,
    title: 'Навык: Работник месяца',
    description: 'Назовите как можно больше этапов продаж.',
    subDescription: 'За каждый верный ответ +1 шаг по полю.',
    timer: 20,
    answer:
      '\nПоиск\nУстановление контакта\nВыявление потребностей\nПрезентация\nОтработка возражений\nЗакрытие сделки\nПослепродажное сопровождение',
  },
  {
    id: 15,
    number: 15,
    title: 'Навык: Знаток истории',
    description: 'Как изначально назывался банк ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
    answer: 'Банк Внешней торговли/Внешторгбанк',
  },
  {
    id: 16,
    number: 16,
    title: 'Навык: Работник месяца',
    description: 'Сколько стоит годовое обслуживание дебетовой карты ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
    answer: 'Бесплатно',
  },
  {
    id: 17,
    number: 17,
    title: 'Навык: Знаток истории',
    description: 'В каком городе впервые был зарегистрирован ВТБ?',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
    answer: 'в Санкт-Петербурге',
  },
  {
    id: 18,
    number: 18,
    title: 'Навык: Командная работа',
    description: 'Назовите каждого участника своей команды по имени без ошибок.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 19,
    number: 19,
    title: 'Навык: Лучше, чем ПоверПоинт',
    description:
      'ВТБ выпустил новые экологичные ручки. Расскажи о них, используя фишки качественной презентации, или опиши ручку так, чтобы ведущий захотел ее купить.',
    subDescription: 'Если выполнили задание, идите вперед на 6 шагов.',
    timer: 60,
  },
  {
    id: 20,
    number: 20,
    description:
      'Вы загадали должность работника банка. \nНазовите его основные задачи остальным участникам. Ведущий сообщит вам подробности лично.',
    subDescription: 'Первая угадавшая команда двигается вперед на 6 шагов вместе с вами',
    timer: 60,
    answer: 'Менеджер',
  },
  {
    id: 21,
    number: 21,
    description:
      'Вы загадали должность работника банка. \nНазовите его основные задачи остальным участникам. Ведущий сообщит вам подробности лично. ',
    subDescription: 'Первая угадавшая команда двигается вперед на 6 шагов вместе с вами.',
    timer: 60,
    answer: 'Финансист',
  },
  {
    id: 22,
    number: 22,
    description: 'Задание зачитает ведущий.',
  },
  {
    id: 23,
    number: 23,
    title: 'Навык: Напарник мечты',
    description:
      'Выберите одного из игроков чужой команды и объясните ему 5 слов, не используя однокоренные. Можно объяснять слова не по порядку.',
    subDescription:
      'За каждое отгаданное слово обе команды продвигаются на 1 шаг. Если угаданы все слова, обе команды получают навык «Напарник мечты».',
    timer: 60,
    answer: '\nБанк\nВТБ\nОтделение\nКарта\nВалюта',
  },
  {
    id: 24,
    number: 24,
    title: 'Навык: Напарник мечты',
    description:
      'Выберите одного из игроков чужой команды и объясните ему 5 слов, не используя однокоренные. Можно объяснять слова не по порядку.',
    subDescription:
      'За каждое отгаданное слово обе команды продвигаются на 1 шаг. Если угаданы все слова, обе команды получают навык «Напарник мечты».',
    timer: 60,
    answer: '\nПрезентация\nСделка\nВозражение\nПродажа\nКонтакт',
  },
  {
    id: 25,
    number: 25,
    title: 'Навык: Напарник мечты',
    description:
      'Выберите одного из игроков чужой команды и объясните ему 5 слов, не используя однокоренные. Можно объяснять слова не по порядку.',
    subDescription:
      'За каждое отгаданное слово обе команды продвигаются на 1 шаг. Если угаданы все слова, обе команды получают навык «Напарник мечты».',
    timer: 60,
    answer: '\nБанкомат\nКриптовалюта\nКэшбек\nКлиент\nМобильный банк',
  },
  {
    id: 26,
    number: 26,
    title: 'Навык: Напарник мечты',
    description:
      'Выберите одного из игроков чужой команды и объясните ему 5 слов, не используя однокоренные. Можно объяснять слова не по порядку.',
    subDescription:
      'За каждое отгаданное слово обе команды продвигаются на 1 шаг. Если угаданы все слова, обе команды получают навык «Напарник мечты».',
    timer: 60,
    answer: '\nПремия\nГорящие сердца\nОтчёт\nРабочее место\nКоллега',
  },
  {
    id: 27,
    number: 27,
    description:
      'Объясни слова своей команде без использования однокоренных слов. Можно объяснять слова не по порядку.',
    subDescription: 'За каждое отгаданное слово команда продвигается на 1 шаг.',
    timer: 60,
    answer: '\nДивиденды\nПроцент\nСбережения\nКасса',
  },
  {
    id: 28,
    number: 28,
    description:
      'Объясни слова своей команде без использования однокоренных слов. Можно объяснять слова не по порядку.',
    subDescription: 'За каждое отгаданное слово команда продвигается на 1 шаг.',
    timer: 40,
    answer: '\nИнвестиции\nДоход\nДоговор\nВклад',
  },
  {
    id: 29,
    number: 29,
    description:
      'Объясни слова своей команде без использования однокоренных слов. Можно объяснять слова не по порядку.',
    subDescription: 'За каждое отгаданное слово команда продвигается на 1 шаг.',
    timer: 40,
    answer: '\nИнвестор\nПортфель\nАкции\nИпотека',
  },
  {
    id: 30,
    number: 30,
    description:
      'Наставник заболел и отправил вам задачу, однако произошла ошибка и письмо дошло не полностью, команде нужно его разгадать. \nОдин участник от команды показывает жестами сообщение, а команда должна разгадать шифр.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Распечатать отчет',
  },
  {
    id: 31,
    number: 31,
    description:
      'Наставник заболел и отправил вам задачу, однако произошла ошибка и письмо дошло не полностью, команде нужно его разгадать. \nОдин участник от команды показывает жестами сообщение, а команда должна разгадать шифр.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Составить график',
  },
  {
    id: 32,
    number: 32,
    description:
      'Наставник заболел и отправил вам задачу, однако произошла ошибка и письмо дошло не полностью, команде нужно его разгадать. \nОдин участник от команды показывает жестами сообщение, а команда должна разгадать шифр.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Позвонить клиенту',
  },
  {
    id: 33,
    number: 33,
    title: 'Навык: Командная работа',
    description:
      'На общей встрече коллега презентует важный проект, но вдруг он запнулся и забыл важное слово ): \nТы хочешь помочь коллеге и показать это слово жестами, чтобы он скорее вспомнил.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 40,
    answer: 'Дивиденты',
  },
  {
    id: 34,
    number: 34,
    title: 'Навык: Командная работа',
    description:
      'На общей встрече коллега презентует важный проект, но вдруг он запнулся и забыл важное слово ): \nТы хочешь помочь коллеге и показать это слово жестами, чтобы он скорее вспомнил.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 40,
    answer: 'Кредитная история',
  },
  {
    id: 35,
    number: 35,
    title: 'Навык: Командная работа',
    description:
      'На общей встрече коллега презентует важный проект, но вдруг он запнулся и забыл важное слово ): \nТы хочешь помочь коллеге и показать это слово жестами, чтобы он скорее вспомнил.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 40,
    answer: 'Кэшбек',
  },
  {
    id: 36,
    number: 36,
    type: 'audio',
    audio: TaskAudio37,
    description: 'Угадайте звук устройства, которое используется в банке. Прослушать аудиозапись можно два раза.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
    answer: 'Принтер',
  },
  {
    id: 37,
    number: 37,
    type: 'audio',
    audio: TaskAudio39,
    description: 'Угадайте звук устройства, которое используется в банке. Прослушать аудиозапись можно два раза.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
    answer: 'Счетчик банкнот/купюр',
  },
  {
    id: 38,
    number: 38,
    type: 'audio',
    audio: TaskAudio38,
    description: 'Угадайте звук устройства, которое используется в банке. Прослушать аудиозапись можно два раза.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
    answer: 'Банкомат',
  },
  {
    id: 39,
    number: 39,
    type: 'audio',
    audio: TaskAudio40,
    title: 'Навык: Ораторское искусство',
    description: 'Угадайте голос знаменитости из рекламы ВТБ. Прослушать аудиозапись можно два раза.',
    subDescription: 'Если выполнили задание, идите вперед на 6 шагов.',
    timer: 20,
    answer: 'Егор Крид',
  },
  {
    id: 40,
    number: 40,
    type: 'audio',
    audio: TaskAudio41,
    title: 'Навык: Ораторское искусство',
    description: 'Угадайте голос знаменитости из рекламы ВТБ. Прослушать аудиозапись можно два раза.',
    subDescription:
      'Подсказка: Ученый, преподаватель кафедры географии в МГУ, вел свою передачу про животных в течение 40 лет. Умеет изображать пение некоторых диких птиц \n\nЕсли выполнили задание, идите вперед на 6 шагов.',
    timer: 20,
    answer: 'Николай Дроздов',
  },
  {
    id: 41,
    number: 41,
    type: 'audio',
    audio: TaskAudio42,
    title: 'Навык: Ораторское искусство',
    description: 'Угадайте голос знаменитости из рекламы ВТБ. Прослушать аудиозапись можно два раза.',
    subDescription: 'Если выполнили задание, идите вперед на 6 шагов.',
    timer: 20,
    answer: 'Сергей Бурунов',
  },
  {
    id: 42,
    number: 42,
    type: 'list',
    items: ['«А мы рубль девальвировали, девальвировали, да недодевальвировали»'],
    title: 'Навык: Ораторское искусство',
    description: 'Правильно произнесите скороговорку, у вас есть 3 попытки:',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 40,
  },
  {
    id: 43,
    number: 43,
    type: 'list',
    items: ['«Не беги Зина за Горизонтом в магазины, а держи Зина деньги в валютной корзине»'],
    title: 'Навык: Ораторское искусство',
    description: 'Правильно произнесите скороговорку, у вас есть 3 попытки:',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 40,
  },
  {
    id: 44,
    number: 44,
    type: 'list',
    items: ['«Контроллеры проконтролировали неконтролируемые цены и взяли цены под контроль»'],
    title: 'Навык: Ораторское искусство',
    description: 'Правильно проговорите скороговорку, у вас есть 3 попытки:',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 40,
  },
  {
    id: 45,
    number: 45,
    type: 'list',
    items: [
      '«Карл Кларе кредитовал доллары, Клара в бутике скупила Карла квартальный доход. Карл отнес в ломбард Клары кораллы, а Клара Карлу объявила дефолт',
    ],
    title: 'Навык: Ораторское искусство',
    description: 'Правильно проговорите скороговорку, у вас есть 3 попытки:',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 40,
  },
  {
    id: 46,
    number: 46,
    description: 'Задание зачитает ведущий.',
    timer: 30,
  },
  {
    id: 47,
    number: 47,
    description: 'Задание зачитает ведущий.',
    timer: 30,
  },
  {
    id: 48,
    number: 48,
    description: 'Команда, ходившая перед вами, задает вам один вопрос о ВТБ.',
    subDescription:
      'Если вы ответите, то обе команды идут вперед на 5 шагов, если нет, то команда-соперник идет вперед на 3 шага.',
    timer: 20,
  },
  {
    id: 49,
    number: 49,
    description: 'Задайте вопрос на тему финансов команде, ходившей перед вами.',
    subDescription:
      'Если она отвечает неверно, ваша команда продвигается на 4 шага вперед. Если верно — обе команды продвигаются на 4 шага вперед. \nПредоставьте доказательство верного ответа в течение 15 секунд.',
    timer: 60,
  },
  {
    id: 50,
    number: 50,
    type: 'list',
    items: ['Инвестиции и шапку'],
    description: 'Как можно через несколько слов связать:',
    subDescription:
      '(Пример ассоциации ВТБ — Гагарин: ВТБ -> голубой -> море-> отражение -> луна -> звёзды -> Гагарин) \n\nЕсли выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
  },
  {
    id: 51,
    number: 51,
    type: 'list',
    items: ['Банк и платок'],
    description: 'Как можно через несколько слов связать:',
    subDescription:
      '(Пример ассоциации ВТБ — Гагарин: ВТБ -> голубой -> море-> отражение -> луна -> звёзды -> Гагарин) \n\nЕсли выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
  },
  {
    id: 52,
    number: 52,
    type: 'list',
    items: ['Клиента и парк'],
    description: 'Как можно через несколько слов связать:',
    subDescription:
      '(Пример ассоциации ВТБ — Гагарин: ВТБ -> голубой -> море-> отражение -> луна -> звёзды -> Гагарин) \n\nЕсли выполнили задание, идите вперед на 5 шагов.',
    timer: 20,
  },
  {
    id: 53,
    number: 53,
    description: 'Придумайте три нестандартных способа использования принтера.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 54,
    number: 53,
    description: 'Придумайте три нестандартных способа использования банковской карты.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 55,
    number: 55,
    description: 'Придумайте три нестандартных способа использования наличных денег.',
    subDescription: 'Если выполнили задание, идите вперед на 4 шага.',
    timer: 20,
  },
  {
    id: 56,
    number: 56,
    type: 'image',
    image: TaskImage57,
    description: 'Разгадайте ребус:',
    subDescription: 'Если выполнили задание, идите на 5 шагов вперед.',
    timer: 20,
    answer: 'Надежность',
  },
  {
    id: 57,
    number: 57,
    type: 'image',
    image: TaskImage58,
    description: 'Разгадайте ребус:',
    subDescription: 'Если выполнили задание, идите на 5 шагов вперед.',
    timer: 20,
    answer: 'Велобайк',
  },
  {
    id: 58,
    number: 58,
    type: 'image',
    image: TaskImage59,
    description: 'Разгадайте ребус:',
    subDescription: 'Если выполнили задание, идите на 5 шагов вперед.',
    timer: 20,
    answer: 'Карточка',
  },
  {
    id: 59,
    number: 59,
    description:
      'Команда должна узнать, какое слово загадал ведущий. \nВо время игры используйте вопросы, на которые ведущий может ответить да/нет.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Риск',
  },
  {
    id: 60,
    number: 60,
    description:
      'Команда должна узнать, какое слово загадал ведущий. \nВо время игры используйте вопросы, на которые ведущий может ответить да/нет.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Валюта',
  },
  {
    id: 61,
    number: 61,
    description:
      'Команда должна узнать, какое слово загадал ведущий. \nВо время игры используйте вопросы, на которые ведущий может ответить да/нет.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Стажёр',
  },
  {
    id: 62,
    number: 62,
    description:
      'Команда должна узнать, какое слово загадал ведущий. \nВо время игры используйте вопросы, на которые ведущий может ответить да/нет.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 60,
    answer: 'Кошелек',
  },
  {
    id: 63,
    number: 63,
    description:
      'Вы обратились в банк с запросом: \n«Наша команда хочет получать ежемесячно пассивный доход. Какое действие вы посоветуете нам совершить?». \n\nВерный ответ ведущий сообщит вам лично. \nВыберите любую команду, которой необходимо найти загаданное решение. \nОна должна задавать вопросы, на которые вы можете ответить да/нет. Ответом будет являться продукт или услуга Банка ВТБ.',
    subDescription: 'Если выполнили задание, вы и команда, угадавшая фразу, идете вперед на 6 шагов.',
    timer: 90,
    answer: 'Покупка акций ВТБ',
  },
  {
    id: 64,
    number: 64,
    description:
      'Вы обратились в банк с запросом: \n«Наша команда хочет купить онлайн-курс по развитию навыков в продажах. Каким продуктом нам стоит воспользоваться?» \n\nВерный ответ ведущий сообщит вам лично. \nВыберите любую команду, которой необходимо найти загаданное решение. \nОна должна задавать вопросы, на которые вы можете ответить да/нет. Ответом будет являться продукт или услуга Банка ВТБ.',
    subDescription: 'Если выполнили задание, вы и команда, угадавшая фразу, идете вперед на 6 шагов.',
    timer: 90,
    answer: 'Банковская карта',
  },
  {
    id: 65,
    number: 65,
    description:
      'Каждый из участников команды по цепочке должен назвать слова, связанные с банковской сферой на букву В.',
    subDescription: 'За каждое правильное слово +1 шаг на поле',
    timer: 15,
  },
  {
    id: 66,
    number: 66,
    description:
      'Каждый из участников команды по цепочке должен назвать слова, связанные с банковской сферой на букву Т.',
    subDescription: 'За каждое правильное слово +1 шаг на поле',
    timer: 15,
  },
  {
    id: 67,
    number: 67,
    description:
      'Каждый из участников команды по цепочке должен назвать слова, связанные с банковской сферой на букву Б.',
    subDescription: 'За каждое правильное слово +1 шаг на поле',
    timer: 15,
  },
  {
    id: 68,
    number: 68,
    type: 'list',
    items: [
      'С чем должен сходиться дебет?',
      'Нужно ли погашать задолженность по дебетовой карте?',
      'На какой купюре изображен город Владивосток?',
    ],
    columns: 1,
    title: 'Повышение квалификации',
    description:
      'Пришло время пройти повышение квалификации. Если ответите на 3 вопроса, можете сразу перейти на следующую должность.',
    subDescription: 'На каждый вопрос у вас есть 15 секунд.',
    timer: 45,
    answer: '\n1. С кредитом \n2. Нет \n3. Купюра номиналом 2000 рублей',
  },
  {
    id: 69,
    number: 69,
    description: 'Задание зачитает ведущий.',
  },
  {
    id: 70,
    number: 70,
    type: 'list',
    items: [
      'Какая валюта используется в Швейцарии?',
      'Как называется процесс обесценивания денег за счет роста цен?',
      'Можно ли оформить карточку в банке за другого человека?',
    ],
    columns: 1,
    title: 'Повышение квалификации',
    description:
      'Пришло время пройти повышение квалификации. Если ответите на 3 вопроса, можете сразу перейти на следующую должность.',
    subDescription: 'На каждый вопрос у вас есть 15 секунд.',
    timer: 45,
    answer: '\n1. Франк \n2. Инфляция \n3. Нет',
  },
  {
    id: 71,
    number: 71,
    title: 'Навык: Командная работа',
    description:
      'Вы собираетесь поехать в Школу руководителей группы, но ваш автобус сегодня опаздывает. Коллеги готовы вас подвезти, но вам нужно дать подсказку, откуда вас забрать. \n\nОпишите любое место в вашем городе или университете, которое известно всем, не называя его.',
    subDescription: 'Если выполнили задание, идите вперед на 5 шагов.',
    timer: 40,
  },
  {
    id: 72,
    number: 72,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Ведущий быстро называет 5 цифр (с плюсом или минусом), команда должна посчитать в уме и дать правильный ответ',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 20,
    answer: '7 + 4 - 3 + 10 - 2 = 18',
  },
  {
    id: 73,
    number: 73,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Ведущий быстро называет 5 цифр (с плюсом или минусом), команда должна посчитать в уме и дать правильный ответ',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 20,
    answer: '1 + 12 - 5 + 4 - 9 = 3',
  },
  {
    id: 74,
    number: 74,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Ведущий быстро называет 10 цифр (с плюсом или минусом), команда должна посчитать в уме и дать правильный ответ',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 30,
    answer: '2 + 1 + 3 - 5 + 8 - 2 - 3 + 6 - 2 + 1 = 9',
  },
  {
    id: 75,
    number: 75,
    title: 'Навык: Тайм-менеджмент',
    description:
      'Ведущий быстро называет 10 цифр (с плюсом или минусом), команда должна посчитать в уме и дать правильный ответ',
    subDescription: 'Если выполнили задание: идите вперед на 6 шагов',
    timer: 30,
    answer: '2 + 4 - 3 + 10 + 7 - 6 + 3 - 8 + 9 - 1 = 17',
  },
  {
    id: 76,
    number: 76,
    description: 'Какую программу, направленную на развитие нетворкинга, реализует ВТБ?',
    subDescription:
      'Подсказка: ответ стоит искать в карьерной группе ВТБ Вконтакте \n\nЕсли выполнили задание: идите вперед на 6 шагов',
    timer: 60,
    answer: 'Именные гранты ВТБ / Хакатон More.Tech',
    answerLink: ['https://vk.com/vtb_career?w=wall-214504417_181', 'https://vk.com/vtb_career?w=wall-214504417_81'],
  },
  {
    id: 77,
    number: 77,
    type: 'image',
    image: TaskImage79,
    title: 'Навык: Корпоративная культура',
    description: 'Откуда эта фотография?',
    subDescription:
      'Подсказка: ответ находится в ТГ-канале ВТБ Карьера, в посте о рабочем месте :) \n\nЕсли выполнили задание: идите вперед на 4 шага',
    timer: 60,
    answer: 'Офис ВТБ в Москва-Сити',
    answerLink: 'https://t.me/vtb_career/61',
  },
  {
    id: 78,
    number: 78,
    title: 'Навык: Банкир',
    description: 'Какой курс японской иены у ВТБ сейчас?',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов.',
    timer: 60,
    answerLink: 'https://www.vtb.ru/personal/platezhi-i-perevody/obmen-valjuty/',
  },
  {
    id: 79,
    number: 79,
    description: 'Найдите на сайте.\n\nКакая почта у горячей линии ВТБ для частных лиц?',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 60,
    answer: 'info@vtb.ru',
    answerLink: 'https://www.vtb.ru/about/contacts/',
  },
  {
    id: 80,
    number: 80,
    description: 'Найдите на сайте ВТБ. \n\nСколько стоит страховка от мошенников для пенсионеров?',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 60,
    answer: 'Бесплатно',
    answerLink: 'https://www.vtb.ru/personal/pensioneram',
  },
  {
    id: 81,
    number: 81,
    description:
      'Найдите на карьерном сайте ВТБ информацию о стандартных этапах отбора в IT команду ВТБ. \nНазовите этапы отбора',
    subDescription: 'Если выполнили задание: идите вперед на 6 шагов.',
    timer: 60,
    answer:
      '\n1. Отклик на вакансию\n2. Звонок/письмо рекрутера\n3. Техническое собеседование\n4. Финальное интервью\n5. Оффер\n6. Выход на работу',
    answerLink: 'https://vtbcareer.com/it/recruitment',
  },
  {
    id: 82,
    number: 82,
    description: 'Найдите на карьерном сайте ВТБ информацию о предстоящих стажировках и назовите минимум три направления',
    subDescription: 'Если выполнили задание: идите вперед на 6 шагов',
    timer: 60,
    answer:
      '\nВТБ Юниор в головной организации\nВТБ Юниор для сети банка в Москве и регионах\nВТБ РОСТ\nШкола по информационной безопасности\nШкола аналитики',
    answerLink: 'https://vtbcareer.com/internship/',
  },
  {
    id: 83,
    number: 83,
    description: 'Найдите на сайте почтовый адрес ПАО ВТБ и озвучьте его',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 60,
    answer: '191144, г. Санкт-Петербург, пер. Дегтярный, д. 11 литера А',
    answerLink: 'https://vtb.ru/about/contacts/',
  },
  {
    id: 84,
    number: 84,
    title: 'Навык: Знаток банка',
    description:
      'Вопрос ко всем командам. Кто первым ответит верно, продвигается вперед вместе с командой, которая вытянула задание. Если сама команда отвечает первой, она двигается вперед одна. \n\nКакой номер телефона у горячей линии ВТБ по России?',
    subDescription: 'Если выполнили задание: идите вперед на 5 шагов',
    timer: 60,
    answer: '8 (800) 100-24-24',
    answerLink: 'https://vtb.ru/',
  },
];
