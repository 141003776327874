import { RETAIL_DEPARTMENT_FIELDS, CONTACT_CENTER_FIELDS } from './fields';

export const RETAIL_DEPARTMENT_BOARD = {
  path: [
    [4, 4],
    [4, 5],
    [4, 6],
    [5, 6],
    [6, 6],
    [6, 5],
    [6, 4],
    [6, 3],
    [6, 2],
    [5, 2],
    [4, 2],
    [3, 2],
    [2, 2],
    [2, 3],
    [2, 4],
    [2, 5],
    [2, 6],
    [2, 7],
    [2, 8],
    [3, 8],
    [4, 8],
    [5, 8],
    [6, 8],
    [7, 8],
    [8, 8],
    [8, 7],
    [8, 6],
    [8, 5],
    [8, 4],
    [8, 3],
    [8, 2],
    [8, 1],
    [8, 0],
    [7, 0],
    [6, 0],
    [5, 0],
    [4, 0],
    [3, 0],
    [2, 0],
    [1, 0],
    [0, 0],
    [0, 1],
    [0, 2],
    [0, 3],
    [0, 4],
    [0, 5],
    [0, 6],
    [0, 7],
    [0, 8],
  ],
  layout: [
    [
      RETAIL_DEPARTMENT_FIELDS[40],
      RETAIL_DEPARTMENT_FIELDS[41],
      RETAIL_DEPARTMENT_FIELDS[42],
      RETAIL_DEPARTMENT_FIELDS[43],
      RETAIL_DEPARTMENT_FIELDS[44],
      RETAIL_DEPARTMENT_FIELDS[45],
      RETAIL_DEPARTMENT_FIELDS[46],
      RETAIL_DEPARTMENT_FIELDS[47],
      RETAIL_DEPARTMENT_FIELDS[48],
    ],
    [RETAIL_DEPARTMENT_FIELDS[39], null, null, null, null, null, null, null, null],
    [
      RETAIL_DEPARTMENT_FIELDS[38],
      null,
      RETAIL_DEPARTMENT_FIELDS[12],
      RETAIL_DEPARTMENT_FIELDS[13],
      RETAIL_DEPARTMENT_FIELDS[14],
      RETAIL_DEPARTMENT_FIELDS[15],
      RETAIL_DEPARTMENT_FIELDS[16],
      RETAIL_DEPARTMENT_FIELDS[17],
      RETAIL_DEPARTMENT_FIELDS[18],
    ],
    [
      RETAIL_DEPARTMENT_FIELDS[37],
      null,
      RETAIL_DEPARTMENT_FIELDS[11],
      null,
      null,
      null,
      null,
      null,
      RETAIL_DEPARTMENT_FIELDS[19],
    ],
    [
      RETAIL_DEPARTMENT_FIELDS[36],
      null,
      RETAIL_DEPARTMENT_FIELDS[10],
      null,
      RETAIL_DEPARTMENT_FIELDS[0],
      RETAIL_DEPARTMENT_FIELDS[1],
      RETAIL_DEPARTMENT_FIELDS[2],
      null,
      RETAIL_DEPARTMENT_FIELDS[20],
    ],
    [
      RETAIL_DEPARTMENT_FIELDS[35],
      null,
      RETAIL_DEPARTMENT_FIELDS[9],
      null,
      null,
      null,
      RETAIL_DEPARTMENT_FIELDS[3],
      null,
      RETAIL_DEPARTMENT_FIELDS[21],
    ],
    [
      RETAIL_DEPARTMENT_FIELDS[34],
      null,
      RETAIL_DEPARTMENT_FIELDS[8],
      RETAIL_DEPARTMENT_FIELDS[7],
      RETAIL_DEPARTMENT_FIELDS[6],
      RETAIL_DEPARTMENT_FIELDS[5],
      RETAIL_DEPARTMENT_FIELDS[4],
      null,
      RETAIL_DEPARTMENT_FIELDS[22],
    ],
    [RETAIL_DEPARTMENT_FIELDS[33], null, null, null, null, null, null, null, RETAIL_DEPARTMENT_FIELDS[23]],
    [
      RETAIL_DEPARTMENT_FIELDS[32],
      RETAIL_DEPARTMENT_FIELDS[31],
      RETAIL_DEPARTMENT_FIELDS[30],
      RETAIL_DEPARTMENT_FIELDS[29],
      RETAIL_DEPARTMENT_FIELDS[28],
      RETAIL_DEPARTMENT_FIELDS[27],
      RETAIL_DEPARTMENT_FIELDS[26],
      RETAIL_DEPARTMENT_FIELDS[25],
      RETAIL_DEPARTMENT_FIELDS[24],
    ],
  ],
};

export const CONTACT_CENTER_BOARD = {
  path: [
    [4, 4],
    [4, 5],
    [4, 6],
    [5, 6],
    [6, 6],
    [6, 5],
    [6, 4],
    [6, 3],
    [6, 2],
    [5, 2],
    [4, 2],
    [3, 2],
    [2, 2],
    [2, 3],
    [2, 4],
    [2, 5],
    [2, 6],
    [2, 7],
    [2, 8],
    [3, 8],
    [4, 8],
    [5, 8],
    [6, 8],
    [7, 8],
    [8, 8],
    [8, 7],
    [8, 6],
    [8, 5],
    [8, 4],
    [8, 3],
    [8, 2],
    [8, 1],
    [8, 0],
    [7, 0],
    [6, 0],
    [5, 0],
    [4, 0],
    [3, 0],
    [2, 0],
    [1, 0],
    [0, 0],
    [0, 1],
    [0, 2],
    [0, 3],
    [0, 4],
    [0, 5],
    [0, 6],
    [0, 7],
    [0, 8],
  ],
  layout: [
    [
      CONTACT_CENTER_FIELDS[40],
      CONTACT_CENTER_FIELDS[41],
      CONTACT_CENTER_FIELDS[42],
      CONTACT_CENTER_FIELDS[43],
      CONTACT_CENTER_FIELDS[44],
      CONTACT_CENTER_FIELDS[45],
      CONTACT_CENTER_FIELDS[46],
      CONTACT_CENTER_FIELDS[47],
      CONTACT_CENTER_FIELDS[48],
    ],
    [CONTACT_CENTER_FIELDS[39], null, null, null, null, null, null, null, null],
    [
      CONTACT_CENTER_FIELDS[38],
      null,
      CONTACT_CENTER_FIELDS[12],
      CONTACT_CENTER_FIELDS[13],
      CONTACT_CENTER_FIELDS[14],
      CONTACT_CENTER_FIELDS[15],
      CONTACT_CENTER_FIELDS[16],
      CONTACT_CENTER_FIELDS[17],
      CONTACT_CENTER_FIELDS[18],
    ],
    [
      CONTACT_CENTER_FIELDS[37],
      null,
      CONTACT_CENTER_FIELDS[11],
      null,
      null,
      null,
      null,
      null,
      CONTACT_CENTER_FIELDS[19],
    ],
    [
      CONTACT_CENTER_FIELDS[36],
      null,
      CONTACT_CENTER_FIELDS[10],
      null,
      CONTACT_CENTER_FIELDS[0],
      CONTACT_CENTER_FIELDS[1],
      CONTACT_CENTER_FIELDS[2],
      null,
      CONTACT_CENTER_FIELDS[20],
    ],
    [
      CONTACT_CENTER_FIELDS[35],
      null,
      CONTACT_CENTER_FIELDS[9],
      null,
      null,
      null,
      CONTACT_CENTER_FIELDS[3],
      null,
      CONTACT_CENTER_FIELDS[21],
    ],
    [
      CONTACT_CENTER_FIELDS[34],
      null,
      CONTACT_CENTER_FIELDS[8],
      CONTACT_CENTER_FIELDS[7],
      CONTACT_CENTER_FIELDS[6],
      CONTACT_CENTER_FIELDS[5],
      CONTACT_CENTER_FIELDS[4],
      null,
      CONTACT_CENTER_FIELDS[22],
    ],
    [CONTACT_CENTER_FIELDS[33], null, null, null, null, null, null, null, CONTACT_CENTER_FIELDS[23]],
    [
      CONTACT_CENTER_FIELDS[32],
      CONTACT_CENTER_FIELDS[31],
      CONTACT_CENTER_FIELDS[30],
      CONTACT_CENTER_FIELDS[29],
      CONTACT_CENTER_FIELDS[28],
      CONTACT_CENTER_FIELDS[27],
      CONTACT_CENTER_FIELDS[26],
      CONTACT_CENTER_FIELDS[25],
      CONTACT_CENTER_FIELDS[24],
    ],
  ],
};
